<template>
  <div>
    <div class="pricing-modals">
      <div class="register-modal">
        <div class="register-content">
          <div class="register-head">
            <div>JOIN 4KPlayer</div>
          </div>
          <div class="register-body">
            <form class="register-form">
              <div v-if="message" class="error-message">
                {{ message }}
              </div>
              <div class="form-group">
                <label for="register_fname">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="register_fname"
                  v-model="credentials.first_name"
                />
                <div
                  v-if="errorsData.first_name"
                  class="error-icon error-icon-name"
                >
                  <svg
                    id="Group_12953"
                    data-name="Group 12953"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.088"
                    height="18.088"
                    viewBox="0 0 18.088 18.088"
                  >
                    <circle
                      id="Ellipse_4260"
                      data-name="Ellipse 4260"
                      cx="9.044"
                      cy="9.044"
                      r="9.044"
                      fill="#ee5951"
                    ></circle>
                    <g
                      id="Group_8569"
                      data-name="Group 8569"
                      transform="translate(10.103 13.812) rotate(180)"
                    >
                      <circle
                        id="Ellipse_4259"
                        data-name="Ellipse 4259"
                        cx="0.994"
                        cy="0.994"
                        r="0.994"
                        fill="#fff"
                      ></circle>
                      <path
                        id="Path_8229"
                        data-name="Path 8229"
                        d="M.595,0A.6.6,0,0,0,0,.595V5.648a.595.595,0,1,0,1.189,0V.595A.6.6,0,0,0,.595,0Z"
                        transform="translate(0.435 3.61)"
                        fill="#fff"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div v-if="errorsData.first_name" class="text-danger small mb-2">
                {{ errorsData.first_name[0] }}
              </div>
              <div class="form-group">
                <label for="register_lname">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="register_lname"
                  v-model="credentials.last_name"
                />
                <div
                  v-if="errorsData.last_name"
                  class="error-icon error-icon-name"
                >
                  <svg
                    id="Group_12953"
                    data-name="Group 12953"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.088"
                    height="18.088"
                    viewBox="0 0 18.088 18.088"
                  >
                    <circle
                      id="Ellipse_4260"
                      data-name="Ellipse 4260"
                      cx="9.044"
                      cy="9.044"
                      r="9.044"
                      fill="#ee5951"
                    ></circle>
                    <g
                      id="Group_8569"
                      data-name="Group 8569"
                      transform="translate(10.103 13.812) rotate(180)"
                    >
                      <circle
                        id="Ellipse_4259"
                        data-name="Ellipse 4259"
                        cx="0.994"
                        cy="0.994"
                        r="0.994"
                        fill="#fff"
                      ></circle>
                      <path
                        id="Path_8229"
                        data-name="Path 8229"
                        d="M.595,0A.6.6,0,0,0,0,.595V5.648a.595.595,0,1,0,1.189,0V.595A.6.6,0,0,0,.595,0Z"
                        transform="translate(0.435 3.61)"
                        fill="#fff"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div v-if="errorsData.last_name" class="text-danger small mb-2">
                {{ errorsData.last_name[0] }}
              </div>
              <div class="form-group">
                <label for="register_email">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="register_email"
                  name="register_email"
                  v-model="credentials.email"
                />
                <div
                  v-if="errorsData.email"
                  class="error-icon error-icon-email"
                >
                  <svg
                    id="Group_12953"
                    data-name="Group 12953"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.088"
                    height="18.088"
                    viewBox="0 0 18.088 18.088"
                  >
                    <circle
                      id="Ellipse_4260"
                      data-name="Ellipse 4260"
                      cx="9.044"
                      cy="9.044"
                      r="9.044"
                      fill="#ee5951"
                    ></circle>
                    <g
                      id="Group_8569"
                      data-name="Group 8569"
                      transform="translate(10.103 13.812) rotate(180)"
                    >
                      <circle
                        id="Ellipse_4259"
                        data-name="Ellipse 4259"
                        cx="0.994"
                        cy="0.994"
                        r="0.994"
                        fill="#fff"
                      ></circle>
                      <path
                        id="Path_8229"
                        data-name="Path 8229"
                        d="M.595,0A.6.6,0,0,0,0,.595V5.648a.595.595,0,1,0,1.189,0V.595A.6.6,0,0,0,.595,0Z"
                        transform="translate(0.435 3.61)"
                        fill="#fff"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div v-if="errorsData.email" class="text-danger small mb-2">
                {{ errorsData.email[0] }}
              </div>
              <div class="form-group">
                <label for="register_password">Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="register_password"
                  v-model="credentials.password"
                />
                <div
                  v-if="errorsData.password"
                  class="error-icon error-icon-password"
                >
                  <svg
                    id="Group_12953"
                    data-name="Group 12953"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.088"
                    height="18.088"
                    viewBox="0 0 18.088 18.088"
                  >
                    <circle
                      id="Ellipse_4260"
                      data-name="Ellipse 4260"
                      cx="9.044"
                      cy="9.044"
                      r="9.044"
                      fill="#ee5951"
                    ></circle>
                    <g
                      id="Group_8569"
                      data-name="Group 8569"
                      transform="translate(10.103 13.812) rotate(180)"
                    >
                      <circle
                        id="Ellipse_4259"
                        data-name="Ellipse 4259"
                        cx="0.994"
                        cy="0.994"
                        r="0.994"
                        fill="#fff"
                      ></circle>
                      <path
                        id="Path_8229"
                        data-name="Path 8229"
                        d="M.595,0A.6.6,0,0,0,0,.595V5.648a.595.595,0,1,0,1.189,0V.595A.6.6,0,0,0,.595,0Z"
                        transform="translate(0.435 3.61)"
                        fill="#fff"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div v-if="errorsData.password" class="text-danger small mb-2">
                {{ errorsData.password[0] }}
              </div>
              <div class="form-group">
                <label for="register_code">Code</label>
                <input
                  type="text"
                  class="form-control"
                  id="register_code"
                  v-model="credentials.code"
                />
                <div
                  v-if="errorsData.code"
                  class="error-icon error-icon-password"
                >
                  <svg
                    id="Group_12953"
                    data-name="Group 12953"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.088"
                    height="18.088"
                    viewBox="0 0 18.088 18.088"
                  >
                    <circle
                      id="Ellipse_4260"
                      data-name="Ellipse 4260"
                      cx="9.044"
                      cy="9.044"
                      r="9.044"
                      fill="#ee5951"
                    ></circle>
                    <g
                      id="Group_8569"
                      data-name="Group 8569"
                      transform="translate(10.103 13.812) rotate(180)"
                    >
                      <circle
                        id="Ellipse_4259"
                        data-name="Ellipse 4259"
                        cx="0.994"
                        cy="0.994"
                        r="0.994"
                        fill="#fff"
                      ></circle>
                      <path
                        id="Path_8229"
                        data-name="Path 8229"
                        d="M.595,0A.6.6,0,0,0,0,.595V5.648a.595.595,0,1,0,1.189,0V.595A.6.6,0,0,0,.595,0Z"
                        transform="translate(0.435 3.61)"
                        fill="#fff"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
              <div v-if="errorsData.code" class="text-danger small mb-2">
                {{ errorsData.code[0] }}
              </div>
              <div class="text-center">
                <button
                  :disabled="loading"
                  type="button"
                  class="submit-user-btn btn"
                  @click="register()"
                >
                  {{ loading ? "Please Wait..." : "Sign up" }}
                </button>
              </div>
            </form>
          </div>
          <div class="register-foot text-center">
            <p style="line-height: 1; margin-top: 3px">
              <span class="faded font-13"
                >By joining Adilo, you agree to our</span
              >
              <br />
              <span
                @click="openLink('https://bigcommand.com/terms')"
                class="links font-13"
                >Terms of Service</span
              >
              <span class="faded font-13 mx-3">and</span>
              <span
                @click="openLink('https://bigcommand.com/privacy')"
                class="links font-13"
                >Privacy Policy</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
export default {
  name: "ViededingueSignUp",
  data() {
    return {
      loading: false,
      message: null,
      credentials: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        code: null,
      },
      errorsData: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        code: null,
      },
    };
  },
  computed: {},
  methods: {
    ...mapMutations({
      setAccessToken: "setAccessToken",
      setUserInfo: "setUserInfo",
      destroyAccessToken: "destroyAccessToken",
    }),

    ...mapActions({
      registerUser: "registerUser",
      getPlans: "getPlans",
      registerForeverFreePlan: "registerForeverFreePlan",
    }),

    openLink(link) {
      window.open(link, '_blank');
    },

    clearErrors() {
      this.errorsData.first_name = null;
      this.errorsData.last_name = null;
      this.errorsData.email = null;
      this.errorsData.password = null;
      this.errorsData.code = null;
      this.message = null;
    },

    async register() {
    //   await this.$recaptchaLoaded();
    //   const token = await this.$recaptcha("LOGIN");
      let credentials = this.credentials;
      this.loading = true;
    //   credentials.recaptcha = token;
      credentials.viededingue = 1;

      this.clearErrors();
      this.registerUser({ ...credentials })
        .then((response) => {
          this.loading = false;
          if (response.data.result === "success") {
            this.setUserInfo(response.data.userInfo);
            window.BCookie.set("BC-Auth-Email", response.data.email, 259200);
            const { access_token: accessToken, expires_in: tokenExpired } =
              response.data;
            this.setAccessToken({
              token: accessToken,
              expires: tokenExpired,
            });
            window.location = "/";
          } else {
            if (response.data.result == "blocked") {
              this.errors.email = "This email is not allowed to sign up.";
            } else {
              this.errors.email =
                "This email is already registered. Please try a different email address.";
            }
            this.destroyAccessToken();
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
          if (error.response.status === 422) {
            if (error.response.data.errors) {
              this.errorsData.first_name =
                error.response.data.errors?.first_name ?? null;
              this.errorsData.last_name =
                error.response.data.errors?.last_name ?? null;
              this.errorsData.email = error.response.data.errors?.email ?? null;
              this.errorsData.password =
                error.response.data.errors?.password ?? null;
              this.errorsData.code = error.response.data.errors?.code ?? null;
            }
          } else if (error.response.status === 400) {
            this.message = "The code is invalid";
          }
          this.destroyAccessToken();
        });
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
body {
  font-family: "Lato", sans-serif;
}

/* Style the tab */
.tab {
  float: left;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  width: 30%;
  height: 400px;
}

/* Style the buttons inside the tab */
.tab button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 22px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current 'tab button' class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  float: left;
  padding: 0px 12px;
  border: 1px solid #ccc;
  width: 70%;
  border-left: none;
  height: 400px;
}

body {
  font-family: Arial;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

/* ====== */

.warning-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #151d2ea2;
  overflow-y: auto;
  z-index: 9999999999999999;
}

.warning-modal .warning-content {
  width: max-content;
  min-width: 200px;
  text-align: center;
  padding: 15px;
  width: -moz-max-content;
  max-height: 50px;
  height: auto;
  background: #ffffff;
  border-radius: 5px;
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  /* bottom: 0; */
  margin: auto;
  animation-name: appear;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
}

.register-modal,
.login-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #151d2ece;
  overflow-y: auto;
  z-index: 999999999;
}
.register-modal .register-content,
.login-modal .login-content {
  width: 400px;
  height: max-content;
  background: #ffffff;
  border-radius: 5px;
  position: absolute;
  left: 0;
  right: 0;
  top: 100px;
  /* bottom: 0; */
  margin: auto;
  animation-name: appear;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
}

.register-modal .register-content .register-head,
.login-modal .login-content .login-head {
  background: #f7f7f7;
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: arial;
  font-size: 21px;
  font-weight: 600;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  align-items: center;
  padding: 30px;
  color: #21455e;
}

.register-modal .register-content .register-head .close-btn,
.login-modal .login-content .login-head .close-btn {
  cursor: pointer;
  margin-bottom: 3px;
}
.register-modal .register-content .register-body .register-form,
.login-modal .login-content .login-body .login-form {
  padding: 10px 30px;
}

.register-modal .register-content .register-body .register-form label,
.login-modal .login-content .login-body .login-form label {
  color: #21455e;
  font-size: 14px;
  opacity: 1;
  height: 15px;
  display: inline-block !important;
  margin-bottom: 0.5rem !important;
}

.register-modal .register-content .register-body .register-form .form-group,
.login-modal .login-content .login-body .login-form .form-group {
  display: flex;
  flex-direction: column;
  height: 55px;
  position: relative;
}

.register-modal
  .register-content
  .register-body
  .register-form
  .submit-user-btn,
.login-modal .login-content .login-body .login-form .login-user-btn {
  text-align: center;
  background: #0ad688;
  color: #fff;
  width: 288px;
  height: 44px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 2px solid transparent;
  padding: 0.5rem 1.5rem;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-weight: 400;
}

.register-modal .register-content .register-body .register-form .error-icon,
.login-modal .login-content .login-body .login-form .error-icon {
  position: absolute;
  right: 10px;
  top: 31px;
}

.register-modal .register-content .register-foot,
.login-modal .login-content .login-foot {
  color: #21455e;
  text-align: center !important;
}

.register-modal .register-content .register-foot p,
.login-modal .login-content .login-foot p {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
}

.register-modal .register-content .register-foot .faded,
.login-modal .login-content .login-foot .faded {
  color: #21455e96;
}

.register-modal .register-content .register-foot .links,
.login-modal .login-content .login-foot .links {
  color: #00acdc;
  cursor: pointer;
}

.font-13 {
  font-size: 10px !important;
}

@keyframes appear {
  from {
    opacity: 0;
    top: 90px;
  }
  to {
    opacity: 1;
    top: 100px;
  }
}

.red-border {
  border: 1px solid #ee5951 !important;
}

div[title]:hover:after {
  content: attr(title);
  position: absolute;
  width: max-content;
  width: -moz-max-content;
  max-width: 200px;
  background: #f7f7f7;
  padding: 15px;
  border-radius: 5px;
  text-align: unset;
  word-break: unset;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-group {
  margin-bottom: 1rem !important;
}
.grecaptcha-badge {
  visibility: hidden;
}
.error-message {
  padding: 17px;
  background: #ea888869;
  color: #ff0000;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
}
</style>
